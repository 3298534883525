/* src/App.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.preloader {
  background: #fff; /* Change the background color as needed */
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

h1 {
  margin: 0;
  font-size: 3em;
}

p {
  margin-top: 10px;
  font-size: 1.2em;
}
